var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('index-filter', {
    attrs: {
      "cols": [3]
    }
  }, [_c('search-filter', {
    attrs: {
      "slot": "col_0"
    },
    slot: "col_0",
    model: {
      value: _vm.filters,
      callback: function callback($$v) {
        _vm.filters = $$v;
      },
      expression: "filters"
    }
  })], 1), _c('v-row', {
    staticClass: "my-0 d-flex justify-end"
  }, [_c('v-col', {
    staticStyle: {
      "margin-right": "auto"
    },
    attrs: {
      "cols": "12",
      "sm": "2",
      "align": "right"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "block": "",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.loadDistributors
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiRefresh) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('common.list.refresh')) + " ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "2",
      "align": "right"
    }
  }, [_c('v-btn', {
    attrs: {
      "depressed": "",
      "block": "",
      "color": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.setAside(null);
      }
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('common.list.create')) + " ")], 1)], 1)], 1), _c('v-card', [_c('v-data-table', {
    staticClass: "text-no-wrap",
    attrs: {
      "headers": _vm.tableColumns,
      "items": _vm.tableList,
      "options": _vm.options,
      "server-items-length": _vm.tableTotal,
      "loading": _vm.loading,
      "header-props": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" #" + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.nicename",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'distributor-view',
              params: {
                id: item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.nicename) + " ")])];
      }
    }, {
      key: "item.site_id",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'site-view',
              params: {
                id: item.site_id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.site.nicename) + " ")])];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.formatDate(item.created_at)))])];
      }
    }, {
      key: "item.active",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.active ? "Yes" : "No"))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-menu', {
          attrs: {
            "bottom": "",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var on = _ref7.on,
                attrs = _ref7.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "size": "18"
                }
              }, [_vm._v(" " + _vm._s(_vm.icons.mdiDotsVertical) + " ")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', [item.type === 'company' ? _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.setCreateUserAside(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-2",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlusCircleOutline) + " ")]), _c('span', [_vm._v("Create user")])], 1)], 1) : _vm._e(), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.setAside(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-2",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPencilOutline) + " ")]), _c('span', [_vm._v("Edit")])], 1)], 1), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.setProfileAside(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-2",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPencilOutline) + " ")]), _c('span', [_vm._v("Edit Profile")])], 1)], 1), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteDistributor(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-2",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiDeleteOutline) + " ")]), _c('span', [_vm._v("Delete")])], 1)], 1)], 1)], 1)], 1)];
      }
    }], null, true),
    model: {
      value: _vm.tableSelectedData,
      callback: function callback($$v) {
        _vm.tableSelectedData = $$v;
      },
      expression: "tableSelectedData"
    }
  })], 1), _vm.aside ? _c('distributor-aside', {
    attrs: {
      "resource": _vm.resource
    },
    on: {
      "changed": function changed($event) {
        _vm.loadDistributors();
        _vm.aside = false;
      },
      "created": function created(data) {
        _vm.profileResource = data;
        _vm.profileAside = true;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e(), _vm.profileAside ? _c('distributor-profile-aside', {
    attrs: {
      "resource": _vm.profileResource
    },
    on: {
      "changed": function changed($event) {
        _vm.loadDistributors();
        _vm.profileAside = false;
      }
    },
    model: {
      value: _vm.profileAside,
      callback: function callback($$v) {
        _vm.profileAside = $$v;
      },
      expression: "profileAside"
    }
  }) : _vm._e(), _vm.createUserAside ? _c('user-aside', {
    attrs: {
      "distributor": _vm.createUserResource
    },
    on: {
      "changed": function changed($event) {
        _vm.createUserAside = false;
      }
    },
    model: {
      value: _vm.createUserAside,
      callback: function callback($$v) {
        _vm.createUserAside = $$v;
      },
      expression: "createUserAside"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }