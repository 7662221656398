<template>
  <div>
    <index-filter :cols="[3]">
      <search-filter
        slot="col_0"
        v-model="filters"
      />
    </index-filter>

    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="2"
        style="margin-right:auto"
        align="right"
      >
        <v-btn
          color="primary"
          block
          class=""
          :loading="loading"
          :disabled="loading"
          @click="loadDistributors"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        align="right"
      >
        <v-btn
          depressed
          block
          color="success"
          @click="setAside(null)"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('common.list.create') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table
        v-model="tableSelectedData"
        :headers="tableColumns"
        :items="tableList"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        :header-props="headerprops"
        class="text-no-wrap"
      >
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>
        <!-- nicename -->
        <template #[`item.nicename`]="{item}">
          <router-link :to="{ name: 'distributor-view', params: { id: item.id } }">
            {{ item.nicename }}
          </router-link>
        </template>

        <!-- site_id -->
        <template #[`item.site_id`]="{item}">
          <router-link :to="{ name: 'site-view', params: { id: item.site_id } }">
            {{ item.site.nicename }}
          </router-link>
        </template>

        <!-- date -->
        <template #[`item.created_at`]="{item}">
          <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
        </template>

        <!-- active -->
        <template #[`item.active`]="{item}">
          <span class="text-no-wrap">{{ item.active ? "Yes" : "No" }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-if="item.type==='company'"
                  link
                  @click="setCreateUserAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPlusCircleOutline }}
                    </v-icon>
                    <span>Create user</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  @click="setAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  @click="setProfileAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit Profile</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  @click="deleteDistributor(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>

                <!-- <v-list-item
                  link
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <distributor-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="loadDistributors(); aside= false"
      @created="data => { profileResource = data; profileAside = true }"
    ></distributor-aside>

    <distributor-profile-aside
      v-if="profileAside"
      v-model="profileAside"
      :resource="profileResource"
      @changed="loadDistributors(); profileAside = false"
    >
    </distributor-profile-aside>

    <!-- <distributor-create-user-aside
      v-if="createUserAside"
      v-model="createUserAside"
      :resource="createUserResource"
      @changed="loadDistributors(); createUserAside = false"
    ></distributor-create-user-aside> -->

    <user-aside
      v-if="createUserAside"
      v-model="createUserAside"
      :distributor="createUserResource"
      @changed="createUserAside = false"
    ></user-aside>
  </div>
</template>

<script>
import {
mdiDeleteOutline, mdiDotsVertical, mdiPencilOutline, mdiPlusCircleOutline,
} from '@mdi/js'

import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'

import { IndexFilter, SearchFilter } from '@/components'
import DistributorAside from '@/modules/distributor/components/distributor/DistributorAside.vue'
import DistributorProfileAside from '@/modules/distributor/components/distributor/DistributorProfileAside.vue'
import UserAside from '@/modules/user/components/list/UserAside.vue'
import useDistributorList from '../composables/useDistributorList'

export default {
  components: {
    DistributorAside,
    DistributorProfileAside,
    UserAside,
    IndexFilter,
    SearchFilter,
  },

  setup() {
    const {
      tableList,
      tableColumns,
      tableTotal,
      tableSelectedData,
      options,
      loadDistributors,
      loading,
      deleteDistributor,
      mdiPlus,
      mdiMenuDown,
      mdiRefresh,
      filters,
    } = useDistributorList()

    const actions = ['Delete', 'Edit', 'Send']

    const selectedAction = ref('')

    const aside = ref(false)
    const resource = ref(null)
    const setAside = item => {
      aside.value = true
      resource.value = item
    }

    const profileAside = ref(false)
    const profileResource = ref(null)
    const setProfileAside = item => {
      profileAside.value = true
      profileResource.value = item
    }

    const createUserAside = ref(false)
    const createUserResource = ref(null)
    const setCreateUserAside = item => {
      createUserAside.value = true
      createUserResource.value = item
    }

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      tableColumns,
      tableList,
      tableSelectedData,
      tableTotal,

      filters,

      loadDistributors,
      deleteDistributor,
      setAside,
      aside,
      resource,

      setProfileAside,
      profileAside,
      profileResource,

      setCreateUserAside,
      createUserAside,
      createUserResource,

      options,
      loading,
      actions,
      selectedAction,

      t,
      formatDate,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiPlusCircleOutline,
        mdiRefresh,
        mdiPlus,
      },
      singleSelect,
      headerprops,
      showFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
